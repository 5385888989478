<template>
  <v-container>
    <h2 id="titulo">
      CITAS
    </h2>
    <v-row>
      <v-col class="ma-2">
        <div
          ref="chart"
          class="hello"
        />
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
import { mapActions, mapGetters} from 'vuex'

export default {
  name: 'Ordxmes',
  components:{
    
  },
  data: () => ({
    grafica:[],
    fecha1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    fecha2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
  }),

  computed:{
    ...mapGetters('controles', ['getControles']),
  },
  
  watch: {
    getControles(){
      // this.limpiar()
      this.actualizarControles()
    },
  },
  
  created(){
    let date0 = new Date()
    let mes = date0.getMonth()
    let anio = date0.getFullYear()
    // console.log(mes, anio)
    this.fecha1 = new Date(anio, mes, 1).toISOString().substr(0, 10)

    this.consultar()
  },

  mounted() {
    this.consultar()
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

  methods:{
    actualizarControles(){
      // console.log("actualiza la grafica por favor",this.getControles)
      this.consultar()
    },
    consultar(){
      // limpiar
      this.grafica= []
      this.labes=[]
      this.value=[]
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      let payload= {
        fecha1 : this.getControles.fecha1,
        fecha2 : this.getControles.fecha2
      }
      this.loading = true
      console.log(payload)


      this.$http.post("v2/grafica.citas.xdia", payload).then(response=>{
        console.log(response)
        if (response.data.error===null && response.data.result != null){
          this.grafica = response.data.result

          this.grafica.forEach( element => {
            element.fecha = element.fecha.substr(5,5)
            //labels
            // this.labels.push(element.fecha)
            //values
            // this.value.push(element.cant)
          })

          console.log("grafica", this.grafica)
          this.graficar()
        }
        this.loading = false


      }).catch(error=>{
        this.loading = false
        console.log(error)
      })

      //Mostar labels y values
      // console.log('value',this.value)
      // console.log('labels',this.labels)
    },

    graficar(){

      let chart = am4core.create(this.$refs.chart, am4charts.XYChart);
      
      chart.paddingRight = 20;
    
      chart.data = this.grafica

      console.log("datos", chart.data)
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      
      categoryAxis.dataFields.category = "fecha"
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.fontSize = 11;

      //ADAPTADOR DE LEYEDA DINAMICO.
      categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
        if (target.dataItem && target.dataItem.index & 2 == 2) {
          return dy + 25;
        }
        return dy;
      });

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Citas";



      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "cant";
      series.dataFields.categoryX = "fecha";
      series.name = "fecha";
      series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = .8;
      series.fontSize = 11;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;

      // let series2 = chart.series.push(new am4charts.LineSeries());
      // series2.name = "Cerradas";
      // series2.stroke = am4core.color("#CDA2AB");
      // series2.strokeWidth = 3;
      // series2.dataFields.valueY = "cerradas";
      // series2.dataFields.categoryX = "fecha";

      this.chart = chart;
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: 400px;
}

#titulo{
  color: white;
  text-align: center;
  background: #3BB8FF;
}
</style>



<!--  mounted() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      chart.paddingRight = 20;

      let data = [];
      let visits = 10;
      
      for (let i = 1; i < 366; i++) {
        visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
      }

      chart.data = this.datos

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";

      series.tooltipText = "{valueY.value}";
      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      this.chart = chart;
  },
 -->